<template>
    <div class="page-news g-page">
        <div class="wp">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path:'/'}">首页</el-breadcrumb-item>
                <el-breadcrumb-item>新闻中心</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="page-tit">
                <h3>新闻中心</h3>
            </div>
            <div class="news-list">
                <div class="item cursorPointer" @click="goNewdetail(item)" v-for="(item,index) in NewsList" :key="index">
                    <div href="#/newsDetail" class="pic">
                        <img :src="item.pictureUrl" alt="">
                    </div>
                    <div class="text">
                        <div class="left">
                            <div class="title newtitle" href="#/newsDetail">{{item.title}}</div>
                            <div class="text_hidden2">
                                <p v-html="`${item.introduce}`"></p>
                            </div>
                            <div href="#/newsDetail" class="g-btn white">查看详情</div>
                        </div>
                        <div class="date">
                            <span>{{item.dd}}</span>
                            <em>/</em>
                            <div>
                                <p>{{item.mm}}月</p>
                                <p>{{ item.yyyy }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="custom-pages">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    @prev-click="handlePrevChange"
                    @next-click="handleNextChange"
                    :current-page="current"
                    :page-sizes="[10, 50, 100]"
                    :page-size="size"
                    layout="total,  prev, pager, next,sizes, jumper"
                    :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import {articleNewspageList} from "@/api/home";

export default {
    name: "News",
    data(){
        return{
            current:1,
            size:10,
            total:0,

            NewsList:[],

        }
    },
    mounted() {
        this.getarticleNewspageList()
    },
    methods:{
        //分页每页下拉显示数据
        handleSizeChange(size) {
            this.size=size;
            this.getarticleNewspageList();
        },
        //分页点击第几页
        handleCurrentChange(currentPage){
            this.current = currentPage;
            this.getarticleNewspageList();
        },
        handlePrevChange(e){ // 上一页
            this.current = e;
            this.getarticleNewspageList();
        },
        handleNextChange(e){ // 下一页
            this.current = e;
            this.getarticleNewspageList();
        },
        goNewdetail(item){
            // query传参数跳转用path (/BrandDetail类似get,url后面会显示参数) params传参数跳转用name (类似post)
            this.$router.push({path:'/NewsDetail',query: {id:item.id}}) //
        },


        getarticleNewspageList(){
            let param={
                current:this.current,
                size:this.size
            }
            articleNewspageList(param).then((res) => { // isHot=1表示推荐
                if (res.data.code==200) {
                    res.data.data.records.forEach((i,index)=>{
                        i.contentText1=i.contentText
                        i.contentText=this.$utils.showHtml(i.contentText)
                        i.contentText=i.contentText.replace(/\u3000/g, "")  // 替换空格符

                        i.yyyy=this.$utils.formatDate(i.publishTime,'yyyy') //发布日期
                        i.mm=this.$utils.formatDate(i.publishTime,'mm')
                        i.dd=this.$utils.formatDate(i.publishTime,'dd')
                    })
                    this.NewsList=res.data.data.records
                    this.total=Number(res.data.data.total)
                }

            })
        },

    },
}
</script>

<style scoped>
.page-news .newtitle{
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #333333;
    font-size: 20px;
    font-weight: bold;
}

</style>
